"use client"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { Button } from "../../../lib/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form"
import { Input } from "../../../lib/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../lib/ui/select"
import axiosInstance from "../../../../axiosInterceptor"
import { Switch } from "../../../lib/ui/switch"


const formSchema = z.object({
    sourceName: z.string().min(1, { message: "Required" }),
    emailSourceId: z.string().min(1, { message: "Required" }),
    fileType: z.string(),
    fromEmail: z.string().min(1, { message: "Required" }).email("Enter a valid email address"),
    subjectValue: z.string().min(3, { message: "Required" }),
    containsNotExact: z.boolean(),
    expectedAttachmentCount: z.string().min(1),
    expectedNumberOfBatchEmails: z.string().min(1),
    attachmentName: z.string(),
    noAttachmentsNeedsLinkClick: z.boolean(),
    linkClickPreceededByText: z.string(),
    linkClickAlwaysContainsText: z.string(),
    downloadSpreadsheet: z.boolean(),
    downloadPdf: z.boolean(),
    spreadsheetDownloadButtonText: z.string(),
    pdfDownloadButtonText: z.string(),
    spreadsheetType: z.string(),
    spreadsheetDownloadName: z.string(),
    pdfDownloadName: z.string(),
    activeStatus: z.boolean(),
    deletedStatus: z.boolean()
});

export function EditOrderSubmissionSources() {
    const { sourceId } = useParams();
    const [emailList, setEmailList] = useState([]);
   
    // 1. Define your form.

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            emailSourceId: "",
            sourceName: "",
            fileType: "",
            fromEmail: "",
            subjectValue: "",
            containsNotExact: false,
            expectedAttachmentCount: "",
            expectedNumberOfBatchEmails: "",
            attachmentName: "",
            noAttachmentsNeedsLinkClick: false,
            linkClickPreceededByText: "",
            linkClickAlwaysContainsText: "",
            downloadSpreadsheet: true,
            downloadPdf: true,
            spreadsheetDownloadButtonText: "",
            pdfDownloadButtonText: "",
            spreadsheetType: "",
            spreadsheetDownloadName: "",
            pdfDownloadName: "",
            activeStatus: true,
            deletedStatus: false,
            
        }
    });

    useEffect(() => {
        if (sourceId !== "0") {
            axiosInstance.get(`/api/order-submission/sources/get/${sourceId}`)
                .then(response => {
                    form.setValue('emailSourceId', response.data.emailSourceId);
                    form.setValue('sourceName', response.data.sourceName);
                    form.setValue('fileType', response.data.fileType);
                    form.setValue('fromEmail', response.data.fromEmail);
                    form.setValue('subjectValue', response.data.subjectValue);
                    form.setValue('containsNotExact', response.data.containsNotExact);
                    form.setValue('expectedAttachmentCount', response.data.expectedAttachmentCount != null ? response.data.expectedAttachmentCount.toString() : '');
                    form.setValue('expectedNumberOfBatchEmails', response.data.expectedNumberOfBatchEmails != null ? response.data.expectedNumberOfBatchEmails.toString() : '');
                    form.setValue('attachmentName', response.data.attachmentName);
                    form.setValue('noAttachmentsNeedsLinkClick', response.data.noAttachmentsNeedsLinkClick);
                    form.setValue('linkClickPreceededByText', response.data.linkClickPreceededByText);
                    form.setValue('linkClickAlwaysContainsText', response.data.linkClickAlwaysContainsText);
                    form.setValue('downloadSpreadsheet', response.data.downloadSpreadsheet);
                    form.setValue('downloadPdf', response.data.downloadPdf);
                    form.setValue('spreadsheetDownloadButtonText', response.data.spreadsheetDownloadButtonText);
                    form.setValue('pdfDownloadButtonText', response.data.pdfDownloadButtonText);
                    form.setValue('spreadsheetType', response.data.spreadsheetType);
                    form.setValue('spreadsheetDownloadName', response.data.spreadsheetDownloadName);
                    form.setValue('pdfDownloadName', response.data.pdfDownloadName);
                    form.setValue('activeStatus', response.data.activeStatus);
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
        axiosInstance.get(`/api/email-sources/email-list`)
            .then(response => {
                setEmailList(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            });
    }, [sourceId, form]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        values["Id"] = parseInt(sourceId);
        const selectedEmail = emailList.find(email => email.item1 === values.emailSourceId);
        if (selectedEmail) {
            values.emailSourceId = selectedEmail.item2;
        }
        axiosInstance.post('/api/order-submission/sources/save', values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    window.location.href = window.location.origin + "/order-submission/sources";
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="emailSourceId"
                            render={({ field }) => (
                                //No matter what changes I make to this I cannot get the existing value to load in the list. 
                                //I have loaded everything from 1 endpoint and set the values all at the same time
                                <FormItem>
                                    <FormLabel>Email Sources</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select an email" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {emailList.map(email => (
                                                <SelectItem key={email.item1} value={email.item2.toString()}>
                                                    {email.item1}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sourceName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Source Name</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="off" placeholder="Company Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>File Type</FormLabel>
                                    <FormControl>
                                        <Input placeholder=".csv" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fromEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>From Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="example@example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="subjectValue"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Subject Value</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email Subject Line" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="containsNotExact"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Subject contains the above text not an exact match</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="expectedAttachmentCount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Attachment Count</FormLabel>
                                    <FormControl>
                                        <Input type="number" placeholder="1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="expectedNumberOfBatchEmails"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Number Of Batch Emails</FormLabel>
                                    <FormControl>
                                        <Input type="number" placeholder="1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="attachmentName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Attachment Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Attachment Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="noAttachmentsNeedsLinkClick"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">No Attachments Expected, Link in email needs to be clicked</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="linkClickPreceededByText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Link Click Preceeded By Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Text before link" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="linkClickAlwaysContainsText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Link Click Always Contains Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Text in link" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="downloadSpreadsheet"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Download Spreadsheet</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="downloadPdf"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Download Pdf</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="spreadsheetDownloadButtonText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Spreadsheet Download Button Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Text on button" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="pdfDownloadButtonText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Pdf Download Button Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Text on button" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="spreadsheetType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Type of spreadsheet</FormLabel>
                                    <FormControl>
                                        <Input placeholder=".csv, .xls, .xlsx" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="spreadsheetDownloadName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name of spreadsheet on download</FormLabel>
                                    <FormControl>
                                        <Input placeholder="output" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="pdfDownloadName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Namne of Pdf on Download</FormLabel>
                                    <FormControl>
                                        <Input placeholder="output" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                </div>
            </form>
        </Form>
    );
}

export default EditOrderSubmissionSources;
