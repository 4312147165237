"use client";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "../../../lib/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form";
import { Input } from "../../../lib/ui/input";
import { Switch } from "../../../lib/ui/switch";
import axiosInstance from "../../../../axiosInterceptor";

const formSchema = z.object({
    id: z.number().int(),
    vendorNameFromFile: z.string().min(1, { message: "Required" }),
    submitEmails: z.string().min(1, { message: "Required" }),
    submitEmailSubjectLine: z.string().min(1, { message: "Required" }),
    submitEmailBodyText: z.string(),
    vendorWantsTrimmedItemDescription: z.boolean(),
    vendorRegexForItemDescription: z.string(),
    vendorWantsNonDailySubmission: z.boolean(),
    vendorCustomSubmitEveryXDay: z.string(),
    vendorWantsPDF: z.boolean(),
    vendorWantsAllData: z.boolean(),
    submitFileHeaderRowIndex: z.string().min(1, { message: "Required" }),
    submitFileTemplate: z.instanceof(File).optional(),
    mapBatch: z.string(),
    mapRep: z.string(),
    mapVendorAddress: z.string(),
    mapVendorPhone: z.string(),
    mapVendorFax: z.string(),
    mapPo: z.string(),
    mapOrderDate: z.string(),
    mapReqShipDate: z.string(),
    mapShipVia: z.string(),
    mapShipTo: z.string(),
    mapAddress1: z.string(),
    mapCareOf: z.string(),
    mapAttention: z.string(),
    mapAddress2: z.string(),
    mapCity: z.string(),
    mapState: z.string(),
    mapZipCode: z.string(),
    mapPhone: z.string(),
    mapEmail: z.string(),
    mapItemDescription: z.string(),
    mapUnitPrice: z.string(),
    mapQty: z.string(),
    mapTotal: z.string(),
    activeStatus: z.boolean(),
    deletedStatus: z.boolean()
});

export function EditOrderSubmissionVendorSource() {
    const { vendorId } = useParams();
    const [fileTemplate, setFileTemplate] = useState<{ fileName: string, fileData: string } | null>(null);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            id: 0,
            vendorNameFromFile: "",
            submitEmails: "",
            submitEmailSubjectLine: "",
            submitEmailBodyText: "",
            vendorWantsTrimmedItemDescription: false,
            vendorRegexForItemDescription: "",
            vendorWantsNonDailySubmission: false,
            vendorCustomSubmitEveryXDay: "",
            vendorWantsPDF: false,
            vendorWantsAllData: false,
            submitFileHeaderRowIndex: "",
            mapBatch: "",
            mapRep: "",
            mapVendorAddress: "",
            mapVendorPhone: "",
            mapVendorFax: "",
            mapPo: "",
            mapOrderDate: "",
            mapReqShipDate: "",
            mapShipVia: "",
            mapShipTo: "",
            mapAddress1: "",
            mapCareOf: "",
            mapAttention: "",
            mapAddress2: "",
            mapCity: "",
            mapState: "",
            mapZipCode: "",
            mapPhone: "",
            mapEmail: "",
            mapItemDescription: "",
            mapUnitPrice: "",
            mapQty: "",
            mapTotal: "",
            activeStatus: true,
            deletedStatus: false,
        }
    });

    useEffect(() => {
        if (vendorId && vendorId !== "0") {
            axiosInstance.get(`/api/order-submission/vendor/get/${vendorId}`)
                .then(response => {
                    const data = response.data;
                    form.setValue('id', Number(vendorId));
                    form.setValue('vendorNameFromFile', data.vendorNameFromFile);
                    form.setValue('submitEmails', data.submitEmails);
                    form.setValue('submitEmailSubjectLine', data.submitEmailSubjectLine);
                    form.setValue('submitEmailBodyText', data.submitEmailBodyText);
                    form.setValue('vendorWantsTrimmedItemDescription', data.vendorWantsTrimmedItemDescription);
                    form.setValue('vendorRegexForItemDescription', data.vendorRegexForItemDescription);
                    form.setValue('vendorWantsNonDailySubmission', data.vendorWantsNonDailySubmission);
                    form.setValue('vendorCustomSubmitEveryXDay', data.vendorCustomSubmitEveryXDay.toString());
                    form.setValue('vendorWantsPDF', data.vendorWantsPDF);
                    form.setValue('vendorWantsAllData', data.vendorWantsAllData);
                    form.setValue('submitFileHeaderRowIndex', data.submitFileHeaderRowIndex.toString());
                    form.setValue('mapBatch', data.mapBatch);
                    form.setValue('mapRep', data.mapRep);
                    form.setValue('mapVendorAddress', data.mapVendorAddress);
                    form.setValue('mapVendorPhone', data.mapVendorPhone);
                    form.setValue('mapVendorFax', data.mapVendorFax);
                    form.setValue('mapPo', data.mapPo);
                    form.setValue('mapOrderDate', data.mapOrderDate);
                    form.setValue('mapReqShipDate', data.mapReqShipDate);
                    form.setValue('mapShipVia', data.mapShipVia);
                    form.setValue('mapShipTo', data.mapShipTo);
                    form.setValue('mapAddress1', data.mapAddress1);
                    form.setValue('mapCareOf', data.mapCareOf);
                    form.setValue('mapAttention', data.mapAttention);
                    form.setValue('mapAddress2', data.mapAddress2);
                    form.setValue('mapCity', data.mapCity);
                    form.setValue('mapState', data.mapState);
                    form.setValue('mapZipCode', data.mapZipCode);
                    form.setValue('mapPhone', data.mapPhone);
                    form.setValue('mapEmail', data.mapEmail);
                    form.setValue('mapItemDescription', data.mapItemDescription);
                    form.setValue('mapUnitPrice', data.mapUnitPrice);
                    form.setValue('mapQty', data.mapQty);
                    form.setValue('mapTotal', data.mapTotal);
                    form.setValue('activeStatus', data.activeStatus);
                    form.setValue('deletedStatus', data.deletedStatus);
                    if (data.submitFileTemplate) {
                        setFileTemplate({
                            fileName: data.submitFileTemplateFilename,
                            fileData: data.submitFileTemplate
                        });
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
    }, [vendorId, form]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        values.vendorCustomSubmitEveryXDay = parseNullableIntToString(values.vendorCustomSubmitEveryXDay);
        if (values.submitFileTemplate) {
            const reader = new FileReader();
            reader.readAsDataURL(values.submitFileTemplate);
            reader.onload = () => {
                const base64Data = reader.result as string;
                const submitFileTemplate = base64Data.split(",")[1];// Remove the data URL prefix
                const submitFileTemplateFileName = values.submitFileTemplate.name;

                const payload = {
                    ...values,
                    submitFileTemplate,
                    submitFileTemplateFileName
                };
                axiosInstance.post('/api/order-submission/vendor/save', payload, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            window.location.href = window.location.origin + "/order-submission/vendor";
                        } else {
                            console.log('failed');
                        }
                    })
                    .catch(error => {
                        console.error('Request failed:', error);
                    });
            };
        } else {
            axiosInstance.post('/api/order-submission/vendor/save', values, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status === 200 || response.status === 204) {
                        window.location.href = window.location.origin + "/order-submission/vendor";
                    } else {
                        console.log('Failed to save data');
                    }
                })
                .catch(error => {
                    console.error('Request failed:', error);
                });
        }


    }

    function parseNullableIntToString(value: string | undefined): string | null {
        if (value === undefined || value.trim() === "") {
            return null;
        }
        return value;
    }

    const handleDownload = () => {
        if (fileTemplate) {
            const byteCharacters = atob(fileTemplate.fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileTemplate.fileName;
            link.click();
        }

    };
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="vendorNameFromFile"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Vendor Name From File</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="off" placeholder="Vendor Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitEmails"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Emails</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email addresses" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitEmailSubjectLine"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Email Subject Line</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Subject Line" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitEmailBodyText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Email Body Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Body Text" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="vendorWantsTrimmedItemDescription"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Trimmed Item Description</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {form.watch('vendorWantsTrimmedItemDescription') && (
                            <>
                                <FormField
                                    control={form.control}
                                    name="vendorRegexForItemDescription"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Vendor Regex For Item Description</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Regex Pattern" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </>
                        )}
                        <FormField
                            control={form.control}
                            name="vendorWantsNonDailySubmission"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Non-Daily Submission</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {form.watch('vendorWantsNonDailySubmission') && (
                            <>
                        <FormField
                            control={form.control}
                            name="vendorCustomSubmitEveryXDay"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Vendor Custom Submit Every X Day</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Days" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                                />
                            </>
                        )}
                        <FormField
                            control={form.control}
                            name="vendorWantsPDF"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants PDF</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsAllData"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants All Data</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitFileHeaderRowIndex"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit File Header Row Index</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Header Row Index" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitFileTemplate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Upload File Template</FormLabel>
                                    <FormControl>
                                        <Input type="file" accept=".xls,.xlsx" onChange={(e) => {
                                            if (e.target.files && e.target.files[0]) {
                                                field.onChange(e.target.files[0]);
                                            }
                                        }} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {fileTemplate && (
                            <div className="mt-4">
                                <p>Existing File: {fileTemplate.fileName}</p>
                                <Button variant="outline" type="button" onClick={handleDownload}>Download File</Button>
                            </div>
                        )}
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="deletedStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Deleted Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="mapBatch"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Batch</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Batch" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="mapRep"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Rep</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Rep" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorAddress"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Address</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Address" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorPhone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Phone</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Phone" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorFax"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Fax</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Fax" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapPo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map PO</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map PO" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapOrderDate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Order Date</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Order Date" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapReqShipDate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Req Ship Date</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Req Ship Date" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapShipVia"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Ship Via</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Ship Via" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapShipTo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Ship To</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Ship To" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAddress1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Address 1</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Address 1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapCareOf"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Care Of</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Care Of" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAttention"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Attention</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Attention" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAddress2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Address 2</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Address 2" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapCity"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map City</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map City" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapState"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map State</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map State" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapZipCode"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Zip Code</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Zip Code" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapPhone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Phone</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Phone" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapItemDescription"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Item Description</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Item Description" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapUnitPrice"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Unit Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Unit Price" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapQty"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Qty</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Qty" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="mapTotal"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Total</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Total" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </form>
        </Form>
    );
}
