'use client'
import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Switch } from '../../../lib/ui/switch'

export type Source = {
    id: string
    activeStatus: boolean
    deletedStatus: boolean
    username: string
    email: string
    password: string
    port: number
    ssl: boolean
    incomingServer: string
}

export const columns: ColumnDef<Source>[] = [
    {
        header: 'Active',
        cell: ({ row }) => (
            <Switch
                checked={row.original.activeStatus}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Set Active Status"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'username',
        header:'Username'
    },
    {
        accessorKey: 'email',
        header: 'Email Address'
    },
    {
        accessorKey: 'incomingServer',
        header: 'Incoming Server'
    },
    {
        accessorKey: 'port',
        header: 'Port'
    },
    {
        accessorKey: 'ssl',
        header: 'Use SSL',
        cell: ({ row }) => (
            <Switch
                checked={row.original.ssl}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Use SSL"
            />
        ),
    },
    {
        id: 'actions',
    }
]