"use client"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { Button } from "../../../lib/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form"
import { Input } from "../../../lib/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../lib/ui/select"
import axiosInstance from "../../../../axiosInterceptor"
import { Switch } from "../../../lib/ui/switch"

const columnNormalizationModelSchema = z.object({
    id: z.number(),
    accountNumber: z.string().nullable(),
    brandName: z.string().nullable(),
    customerName: z.string().nullable(),
    customerNumber: z.string().nullable(),
    customerPoNumber: z.string().nullable(),
    documentNumber: z.string().nullable(),
    estimatedArrivalDate: z.string().nullable(),
    itemNumber: z.string().nullable(),
    itemStatus: z.string().nullable(),
    lineStatus: z.string().nullable(),
    orderDate: z.string().nullable(),
    poDate: z.string().nullable(),
    qty: z.string().nullable(),
    qtyFulfilled: z.string().nullable(),
    shipToName: z.string().nullable(),
    shippedDate: z.string().nullable(),
    supplierName: z.string().nullable(),
    supplierOrderStatus: z.string().nullable(),
    trackingNumbers: z.string().nullable(),
    wildcard1: z.string().nullable(),
    wildcard2: z.string().nullable()
});

const formSchema = z.object({
    sourceName: z.string().min(1, { message: "Required" }),
    emailSourceId: z.string().min(1, { message: "Required" }),
    fileType: z.string().min(3, { message: "Required" }),
    fromEmail: z.string().min(1, { message: "Required" }).email("Enter a valid email address"),
    subjectValue: z.string().min(3, { message: "Required" }),
    containsNotExact: z.boolean(),
    expectedAttachmentCount: z.string().min(1),
    attachmentName: z.string().min(1),
    columnsToIgnore: z.string().nullable(),
    secondaryDelimiter: z.string().max(1).min(0),
    uniqueColumn: z.string().min(3),
    deleteAfterDays: z.string(),
    activeStatus: z.boolean(),
    deletedStatus: z.boolean(),
    columnNormalizationValues: columnNormalizationModelSchema
});

export function EditOrderTrackingSources() {
    const { sourceId } = useParams();
    const [emailList, setEmailList] = useState([]);
    const [columnList] = useState(["AccountNumber",
        "BrandName",
        "CustomerName",
        "CustomerNumber",
        "CustomerPoNumber",
        "DocumentNumber",
        "EstimatedArrivalDate",
        "ItemNumber",
        "ItemStatus",
        "LineStatus",
        "OrderDate",
        "PoDate",
        "QTY",
        "QTYFulfilled",
        "ShipToName",
        "ShippedDate",
        "SupplierName",
        "SupplierOrderStatus",
        "Wildcard1",
        "Wildcard2"]);
    // 1. Define your form.

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            emailSourceId: "",
            sourceName: "",
            fileType: "",
            fromEmail: "",
            subjectValue: "",
            containsNotExact: false,
            expectedAttachmentCount: "",
            attachmentName: "",
            columnsToIgnore: "",
            secondaryDelimiter: "",
            uniqueColumn: "",
            deleteAfterDays: "",
            activeStatus: true,
            deletedStatus: false,
            columnNormalizationValues: {
                id: 0,
                accountNumber: "",
                brandName: "",
                customerName: "",
                customerNumber: "",
                customerPoNumber: "",
                documentNumber: "",
                estimatedArrivalDate: "",
                itemNumber: "",
                itemStatus: "",
                lineStatus: "",
                orderDate: "",
                poDate: "",
                qty: "",
                qtyFulfilled: "",
                shipToName: "",
                shippedDate: "",
                supplierName: "",
                supplierOrderStatus: "",
                trackingNumbers: "",
                wildcard1: "",
                wildcard2: ""
            }
        }
    });

    useEffect(() => {
        if (sourceId !== "0") {
            axiosInstance.get(`/api/order-tracking-sources/get/${sourceId}`)
                .then(response => {
                    form.setValue('emailSourceId', response.data.emailSourceId);
                    form.setValue('sourceName', response.data.sourceName);
                    form.setValue('fileType', response.data.fileType);
                    form.setValue('fromEmail', response.data.fromEmail);
                    form.setValue('subjectValue', response.data.subjectValue);
                    form.setValue('containsNotExact', response.data.containsNotExact);
                    form.setValue('expectedAttachmentCount', response.data.expectedAttachmentCount.toString());
                    form.setValue('attachmentName', response.data.attachmentName);
                    form.setValue('columnsToIgnore', response.data.columnsToIgnore);
                    form.setValue('secondaryDelimiter', response.data.secondaryDelimiter);
                    form.setValue('uniqueColumn', response.data.uniqueColumn.toString());
                    form.setValue('deleteAfterDays', response.data.deleteAfterDays.toString());
                    form.setValue('activeStatus', response.data.activeStatus);
                    //Column Normalization
                    form.setValue('columnNormalizationValues.id', response.data.columnNormalizationValues.id);
                    form.setValue('columnNormalizationValues.accountNumber', response.data.columnNormalizationValues.accountNumber);
                    form.setValue('columnNormalizationValues.brandName', response.data.columnNormalizationValues.brandName);
                    form.setValue('columnNormalizationValues.customerName', response.data.columnNormalizationValues.customerName);
                    form.setValue('columnNormalizationValues.customerNumber', response.data.columnNormalizationValues.customerNumber);
                    form.setValue('columnNormalizationValues.customerPoNumber', response.data.columnNormalizationValues.customerPoNumber);
                    form.setValue('columnNormalizationValues.documentNumber', response.data.columnNormalizationValues.documentNumber);
                    form.setValue('columnNormalizationValues.estimatedArrivalDate', response.data.columnNormalizationValues.estimatedArrivalDate);
                    form.setValue('columnNormalizationValues.itemNumber', response.data.columnNormalizationValues.itemNumber);
                    form.setValue('columnNormalizationValues.itemStatus', response.data.columnNormalizationValues.itemStatus);
                    form.setValue('columnNormalizationValues.lineStatus', response.data.columnNormalizationValues.lineStatus);
                    form.setValue('columnNormalizationValues.orderDate', response.data.columnNormalizationValues.orderDate);
                    form.setValue('columnNormalizationValues.poDate', response.data.columnNormalizationValues.poDate);
                    form.setValue('columnNormalizationValues.qty', response.data.columnNormalizationValues.qty);
                    form.setValue('columnNormalizationValues.qtyFulfilled', response.data.columnNormalizationValues.qtyFulfilled);
                    form.setValue('columnNormalizationValues.shipToName', response.data.columnNormalizationValues.shipToName);
                    form.setValue('columnNormalizationValues.shippedDate', response.data.columnNormalizationValues.shippedDate);
                    form.setValue('columnNormalizationValues.supplierName', response.data.columnNormalizationValues.supplierName);
                    form.setValue('columnNormalizationValues.supplierOrderStatus', response.data.columnNormalizationValues.supplierOrderStatus);
                    form.setValue('columnNormalizationValues.trackingNumbers', response.data.columnNormalizationValues.trackingNumbers);
                    form.setValue('columnNormalizationValues.wildcard1', response.data.columnNormalizationValues.wildcard1);
                    form.setValue('columnNormalizationValues.wildcard2', response.data.columnNormalizationValues.wildcard2);
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
        axiosInstance.get(`/api/email-sources/email-list`)
            .then(response => {
                setEmailList(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            });
    }, [sourceId, form]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        values["Id"] = parseInt(sourceId);
        const selectedEmail = emailList.find(email => email.item1 === values.emailSourceId);
        if (selectedEmail) {
            values.emailSourceId = selectedEmail.item2;
        }
        axiosInstance.post('/api/order-tracking-sources/save', values, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    window.location.href = window.location.origin + "/order-tracking-sources";
                } else {
                    console.log('failed');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="emailSourceId"
                            render={({ field }) => (
                                //No matter what changes I make to this I cannot get the existing value to load in the list. 
                                //I have loaded everything from 1 endpoint and set the values all at the same time
                                <FormItem>
                                    <FormLabel>Email Sources</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select an email" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {emailList.map(email => (
                                                <SelectItem key={email.item1} value={email.item2.toString()}>
                                                    {email.item1}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sourceName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Source Name</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="off" placeholder="Company Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fileType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>File Type</FormLabel>
                                    <FormControl>
                                        <Input placeholder=".csv" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="fromEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>From Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="example@example.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="subjectValue"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Subject Value</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email Subject Line" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="containsNotExact"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Subject contains the above text not an exact match</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="expectedAttachmentCount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Expected Attachment Count</FormLabel>
                                    <FormControl>
                                        <Input type="number" placeholder="1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="attachmentName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Attachment Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Attachment Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="columnsToIgnore"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Columns to Ignore</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Column1, Column2, Column3..." {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="secondaryDelimiter"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Secondary Delimiter</FormLabel>
                                    <FormControl>
                                        <Input placeholder=";" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="uniqueColumn"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Unique Column</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a unique column" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {columnList.map(column => (
                                                <SelectItem key={column} value={column.toString()}>
                                                    {column}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="deleteAfterDays"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Delete After Days</FormLabel>
                                    <FormControl>
                                        <Input type="number" placeholder="1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                    <div>
                        <div className="text-3xl font-bold ml-10 mt-5">Email source file column mappings</div>
                        <div className="flex">
                            <div className="w-1/8 p-4 ml-10">
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.accountNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Account Number</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.brandName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Brand Name</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.customerName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Customer Name</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.customerNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Customer Number</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.customerPoNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Customer PO Number</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.documentNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Document Number</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.estimatedArrivalDate"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Estimated Arrival Date</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-1/8 p-4 ml-10">
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.itemNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Item Number</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.itemStatus"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Item Status</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.lineStatus"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Line Status</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.orderDate"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Order Date</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.poDate"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>PO Date</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.qty"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Quantity</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.qtyFulfilled"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Quantity Fulfilled</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-1/8 p-4 ml-10">
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.shipToName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Ship To Name</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.shippedDate"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Shipped Date</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.supplierName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Supplier Name</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.supplierOrderStatus"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Supplier Order Status</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.trackingNumbers"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Tracking Numbers</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.wildcard1"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Wildcard 1</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="columnNormalizationValues.wildcard2"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Wildcard 2</FormLabel>
                                            <FormControl>
                                                <Input autoComplete="off" placeholder="Source column name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Form>
    );
}

export default EditOrderTrackingSources;
