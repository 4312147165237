import React, { useEffect, useState } from 'react';
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuTrigger,
} from "./lib/ui/navigation-menu"
import { ModeToggle } from './lib/ui/mode-toggle';
import { Button } from './lib/ui/button';
import axiosInstance from '../axiosInterceptor';

function redirectHome() {
    window.location.href = window.location.origin + "/home";
}
function redirectAccount() {
    window.location.href = window.location.origin + "/account";
}
function redirectTrackingSources() {
    window.location.href = window.location.origin + "/order-tracking-sources";
}
function redirectSubmissionSources() {
    window.location.href = window.location.origin + "/order-submission/sources";
}
function redirectSubmissionVendorSources() {
    window.location.href = window.location.origin + "/order-submission/vendor";
}
function redirectEmailSources() {
    window.location.href = window.location.origin + "/email-sources";
}

function redirectInventorySources() {
    window.location.href = window.location.origin + "/inventory-sources";
}
function redirectOrderTracking() {
    window.location.href = window.location.origin + "/order-tracking";
}
function redirectAdmin() {
    window.location.href = window.location.origin + "/admin";
}
function logout() {
    axiosInstance.post('/auth/logout').then(response => {
        window.location.href = window.location.origin + "/";
    });
}

const NavMenu = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(true);
    const [isInventoryUser, setInventoryUser] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                axiosInstance.get('/auth/user').then(response => {
                    setIsAdmin(response?.data?.role === 'Admin')
                    setInventoryUser(response?.data?.inventoryUser === "True");
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    return (
        <header>
            <NavigationMenu className="inline-block min-w-[45%] max-w-[65%]">
                <NavigationMenuList>
                    <NavigationMenuItem className="flex-auto">
                        <Button className="w-1/8" variant="ghost" onClick={redirectHome}>Home</Button>
                        <Button className="w-1/8" variant="ghost" onClick={redirectOrderTracking}>Order Tracking</Button>
                        <Button className="w-1/8" variant="ghost" onClick={redirectEmailSources}>Email Sources</Button>
                        <Button className="w-1/8" variant="ghost" onClick={redirectTrackingSources}>Order Tracking Sources</Button>
                        <Button className="w-1/8" variant="ghost" onClick={redirectSubmissionSources}>Order Submission Sources</Button>
                        {isInventoryUser && <Button className="w-1/8" variant="ghost" onClick={redirectInventorySources}>Inventory Sources</Button>}
                        {isInventoryUser && <Button className="w-1/8" variant="ghost" onClick={redirectSubmissionVendorSources}>Submission Vendor Sources</Button>}
                        {isAdmin && <Button className="w-1/8" variant="ghost" onClick={redirectAdmin}>Admin</Button>}
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
            <NavigationMenu className="inline-block float-right">
                <NavigationMenuList>
                    <NavigationMenuItem>
                        <NavigationMenuTrigger>Account</NavigationMenuTrigger>
                        <NavigationMenuContent>
                            <Button variant="ghost" onClick={redirectAccount}>Manage</Button>
                            <Button variant="ghost" onClick={logout}>Logout</Button>
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <ModeToggle />
                </NavigationMenuList>
            </NavigationMenu>

        </header>
    );
}
export default NavMenu;
